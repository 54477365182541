import React from 'react';
import { FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import fixedImage from '../Certificates/Certificate.png'; // Import the fixed image

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white p-6 relative">
            <h1 className="text-lg font-bold mb-5 text-white">STARRY NIGHTS TOURS AND ADVENTURES</h1>
             {/* Fixed image */}
             <div className="fixed-image-container ">
                <img src={fixedImage} alt="Certificate"  />
            </div>

            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                <section className="contact-us-section">
                    <h2 className="contact-us-heading text-white text-xl font-semibold mb-4">Reach us at</h2>

                    <div className="contact-details space-y-3">
                        <div className="contact-item flex items-center">
                            <span className="contact-icon mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#FFD700">
                                    <path d="M22 16.92v3.1c0 .98-.79 1.76-1.76 1.76-9.56 0-17.33-7.77-17.33-17.33 0-.98.79-1.76 1.76-1.76h3.1c.94 0 1.71.67 1.79 1.61.15 1.77.56 3.5 1.21 5.13.23.58.07 1.25-.38 1.7L8.7 11.6c1.82 3.15 4.74 5.82 8.12 7.06l.57-1.22c.33-.71 1.12-1.05 1.87-.84 1.62.41 3.35.63 5.13.78.94.08 1.61.85 1.61 1.79z"/>
                                </svg>
                            </span>
                            <a href="tel:+918847755042" className="text-white hover:text-gray-300 transition duration-300 text-lg font-medium">
                                +91 884 7755 042
                            </a>
                        </div>
                        <div className="contact-item flex items-center">
                            <span className="contact-icon mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#1E90FF">
                                    <path d="M21.99 5.9L12 12.99 2.01 5.9c-.66-.44-.72-1.33-.15-1.87C2.2 3.6 2.6 3.5 3 3.5h18c.73 0 1.33.59 1.33 1.33 0 .4-.16.77-.45 1.03zM21 7.7L12 14.7 3 7.7V18c0 .73.59 1.33 1.33 1.33h15.33c.74 0 1.34-.6 1.34-1.34V7.7z"/>
                                </svg>
                            </span>
                            <a href="mailto:travelwithstarrynights@gmail.com" className="text-white hover:text-gray-300 transition duration-300 text-lg font-medium">
                                travelwithstarrynights@gmail.com
                            </a>
                        </div>
                        <div className="contact-item flex items-center">
                            <span className="contact-icon mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#FF6347">
                                    <path d="M12 2C8.1 2 5 5.1 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.9-3.1-7-7-7zm0 9.25c-1.24 0-2.25-1-2.25-2.25S10.76 6.75 12 6.75 14.25 7.76 14.25 9 13.24 11.25 12 11.25z"/>
                                </svg>
                            </span>
                            <span className="text-white font-medium">
                                <a
                                    href="https://maps.app.goo.gl/XdpxF664BZL1MYHx6"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white hover:text-gray-300 transition duration-300 text-lg font-medium"
                                >
                                    Branch-1: Pune Banglore highway, Chandani chowk, Pune, Maharashtra, India
                                </a>
                            </span>
                        </div>

                        <div className="contact-item flex items-center">
                            <span className="contact-icon mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#FF6347">
                                    <path d="M12 2C8.1 2 5 5.1 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.9-3.1-7-7-7zm0 9.25c-1.24 0-2.25-1-2.25-2.25S10.76 6.75 12 6.75 14.25 7.76 14.25 9 13.24 11.25 12 11.25z"/>
                                </svg>
                            </span>
                            <span className="text-white font-medium">
                                <a
                                    href="https://maps.app.goo.gl/XdpxF664BZL1MYHx6"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white hover:text-gray-300 transition duration-300 text-lg font-medium"
                                >
                                    Branch-2: Plot no. 004, HUDCO Bus Stop, Nanded.
                                </a>
                            </span>
                        </div>
                    </div>
                </section>

                <div className="mt-6 md:mt-0">
                    <h3 className="text-lg font-bold mb-2 text-white">Follow Us</h3>
                    <div className="flex space-x-4">
                        <a
                            href="https://wa.me/message/UTCOF3APCTTKP1 "
                            className="text-gray-400 hover:text-blue-300 transition duration-300"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaWhatsapp size={24} />
                        </a>
                        <a
                            href="https://www.instagram.com/starrynights.india?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                            className="text-gray-400 hover:text-pink-400 transition duration-300"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaInstagram size={24} />
                        </a>
                        <a
                            href="https://www.linkedin.com/in/darshan-shinde-831410327/"
                            className="text-gray-400 hover:text-blue-600 transition duration-300"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaLinkedin size={24} />
                        </a>
                    </div>
                </div>
            </div>

            <div className="text-center mt-4">
                <p className="text-gray-400 text-sm">
                    &copy; {new Date().getFullYear()} Starry Nights. All rights reserved.
                </p>
            </div>
           
        </footer>
    );
};

export default Footer;
