import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './QueryForm.css';

const QueryForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    destination: '',
    query: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailBody = `Name: ${formData.name}\nContact: ${formData.contact}\nInterested Destination: ${formData.destination}\nQuery: ${formData.query}`;

    window.location.href = `mailto:travelwithstarrynights@gmail.com?subject=Travel Query&body=${encodeURIComponent(emailBody)}`;

    // Delay form reset to allow redirection
    setTimeout(() => {
      setFormData({
        name: '',
        contact: '',
        destination: '',
        query: '',
      });
      window.location.reload(); // Refresh the page
    }, 3000); // Adjust the delay as needed
  };

  return (
    <div className="query-container">
      <h2 className="query-title">Ask Me</h2>
      <form onSubmit={handleSubmit} className="query-form">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          className="query-input"
          required
        />
        <input
          type="tel"
          name="contact"
          placeholder="Your Contact Number"
          value={formData.contact}
          onChange={handleChange}
          className="query-input"
          required
        />
        <input
          type="text"
          name="destination"
          placeholder="Interested Destination Package"
          value={formData.destination}
          onChange={handleChange}
          className="query-input"
          required
        />
        <textarea
          name="query"
          placeholder="Your Query"
          value={formData.query}
          onChange={handleChange}
          className="query-textarea"
          required
        ></textarea>
        <button type="submit" className="query-button">Send Mail</button>
      </form>
      <button className="back-button" onClick={() => navigate('/')}>
        <i className="fas fa-arrow-left"></i> Back
      </button>
    </div>
  );
};

export default QueryForm;
