//Component used under the section of We Provided named as Honeymoon.
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Honeymoon.css';

// Helper function to load all images from the Honeymoon folder
const importAllImages = (requireContext) =>
  requireContext.keys().map((item) => ({
    src: requireContext(item),
    name: item.replace('./', '').replace('.jpg', ''), // Extracting the filename without the path and extension
  }));

const Honeymoon = () => {
  const [images, setImages] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Dynamically import all images from the Honeymoon folder
    const loadedImages = importAllImages(
      require.context('../../Categories Folder/Honeymoon', false, /\.jpg$/)
    );
    setImages(loadedImages);
  }, []);

  const handleImageClick = (imageName) => {
    // Navigate to DetailsPage with the image name in the URL
    navigate(`/details/${imageName}`);
  };

  return (
    <div className="category-details-container">
      <h1 className="categories-title">Honeymoon</h1>
      <div className="categories-grid">
        {images.map((image, index) => (
          <div key={index} className="category-item" onClick={() => handleImageClick(image.name)}>
            <img
              src={image.src}
              alt={`Honeymoon-${index}`}
              className="category-image"
            />
            <p className="image-name">{image.name}</p>
          </div>
        ))}
      </div>
      <button onClick={() => navigate(-1)} className="back-button">
        <i className="fas fa-arrow-left"></i> Back
      </button>
    </div>
  );
};

export default Honeymoon;
