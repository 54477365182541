import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const importAllImages = (requireContext) =>
  requireContext.keys().map((item) => ({
    src: requireContext(item),
    name: item.replace('./', '').replace('.jpg', ''),
  }));

const Himachal = () => {
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadedImages = importAllImages(
      require.context('../../Event Location/Himachal', false, /\.jpg$/)
    );
    setImages(loadedImages);
  }, []);

  const handleImageClick = (imageName) => {
    navigate(`/details/${imageName}`);
  };

  return (
    <div className="category-details-container">
      <h1 className="categories-title">Himachal</h1>
      <div className="categories-grid">
        {images.map((image, index) => (
          <div key={index} className="category-item" onClick={() => handleImageClick(image.name)}>
            <img
              src={image.src}
              alt={`Himachal-${index}`}
              className="category-image"
            />
            <p className="image-name">{image.name}</p>
          </div>
        ))}
      </div>
      <button onClick={() => navigate("/")} className="back-button">
        <i className="fas fa-arrow-left"></i> Back
      </button>
    </div>
  );
};

export default Himachal;
