import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Headerstrip.css';

const Headerstrip = () => {
  const [isSticky, setSticky] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.header-strip');
      if (window.scrollY > header.offsetTop) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      {/* Headerstrip */}
      <header className={`header-strip ${isSticky ? 'sticky' : ''}`}>
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          <div className={`hamburger ${isSidebarOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <nav className="desktop-nav-headerstrip">
          <ul className="nav-list-headerstrip">
            <li><Link to="/">Home</Link></li>|
            <li><Link to="/About">About</Link></li>|
            <li><Link to="/Trending">Trending</Link></li>|
            <li><Link to="/gallery">Gallery</Link></li>|
            <li><Link to="/contacts">Contact</Link></li>|
            <li><a href="mailto:travelwithstarrynight@gmail.com"><i className="fas fa-envelope"></i> Email</a></li>|
            <li><a href="https://wa.me/918847755042" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i> WhatsApp</a></li>|
            <li><Link to="/QueryForm">Enquire Now</Link></li>
          </ul>
        </nav>
      </header>

      {/* Sidebar */}
      {isSidebarOpen && (
        <div className="sidebar-overlay" onClick={closeSidebar}>
          <div className="sidebar-content" onClick={(e) => e.stopPropagation()}>
            <ul>
              <li><Link to="/Maharashtra" onClick={closeSidebar}>Maharashtra</Link></li>
              <li><Link to="/Rajasthan" onClick={closeSidebar}>Rajasthan</Link></li>
              <li><Link to="/Uttrakhand" onClick={closeSidebar}>Uttrakhand</Link></li>
              <li><Link to="/Himachal" onClick={closeSidebar}>Himachal</Link></li>
              <li><Link to="/Kashmir" onClick={closeSidebar}>Kashmir</Link></li>
              <li><Link to="/NEIndia" onClick={closeSidebar}>N E India</Link></li>
              <li><Link to="/Karnataka" onClick={closeSidebar}>Karnataka</Link></li>
              <li><Link to="/Kerala" onClick={closeSidebar}>Kerala</Link></li>
              <li><Link to="/Chennai" onClick={closeSidebar}>Chennai</Link></li>
              <li><Link to="/Treks" onClick={closeSidebar}>Treks</Link></li>
              <li><Link to="/category/Camping" onClick={closeSidebar}>Beach Camping</Link></li>
              
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Headerstrip;
