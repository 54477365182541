import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import StaryNight from '../STARRY-NIGHTS-Header.jpg';
import { FaHome, FaAddressCard, FaImages, FaWhatsapp } from 'react-icons/fa';
import { FaArrowTrendUp } from 'react-icons/fa6';

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div>
      <div>
        <img
          src={StaryNight}
          alt="Stary Nights Header"
          className="w-full h-auto object-cover"
        />
      </div>

      <header className="header">
        {/* Social Media Links in a Separate Row, Aligned Right */}
        <nav className="mobile-bottom-nav">
          <ul className="flex justify-around items-center">
            <li>
              <Link to="/Catalogue" onClick={closeSidebar}>
                <FaArrowTrendUp size={30} />
              </Link>
            </li>

            <li>
              <Link to="/gallery" onClick={closeSidebar}>
                <FaImages size={30} />
              </Link>
            </li>
            
            <li>
              <Link to="/" onClick={closeSidebar}>
                <FaHome size={30} />
              </Link>
            </li>

            <li>
              <Link to="/Contacts" onClick={closeSidebar}>
                <FaAddressCard size={30} />
              </Link>
            </li>

            <li>
              <Link
                to="https://wa.me/message/UTCOF3APCTTKP1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp size={30} />
              </Link>
            </li>
          </ul>
        </nav>

        
      </header>
    </div>
  );
};

export default Header;
