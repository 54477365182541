import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './DetailsPage.css';
import html2pdf from 'html2pdf.js';
import StaryNight from '../STARRY-NIGHTS-LETTER-HEAD.jpg'; // Import header imageF:\my-app\src\STARRY-NIGHTS-LETTER-HEAD.jpg

const DetailsPage = () => {
  const { imageName } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/CategoriesDocs/${imageName}.txt`);

        if (!response.ok || !response.headers.get('Content-Type').includes('text/plain')) {
          setContent('Please contact us for more details.');
          return;
        }

        const textContent = await response.text();
        setContent(textContent);
      } catch (error) {
        setContent('Please contact us for more details.');
      }
    };

    fetchContent();
  }, [imageName]);

  const parseTextWithFormatting = (text) => {
    const regex = /(\*_.*?_\*|\*[^*]+\*|_[^_]+_|~[^~]+~|`[^`]+`)/g;

    return text.split(regex).map((part, index) => {
      if (part.startsWith('*_') && part.endsWith('_*')) {
        return <strong key={index}><em>{part.slice(2, -2)}</em></strong>;
      } else if (part.startsWith('*') && part.endsWith('*')) {
        return <strong key={index}>{part.slice(1, -1)}</strong>;
      } else if (part.startsWith('_') && part.endsWith('_')) {
        return <em key={index}>{part.slice(1, -1)}</em>;
      } else if (part.startsWith('~') && part.endsWith('~')) {
        return <del key={index}>{part.slice(1, -1)}</del>;
      } else if (part.startsWith('`') && part.endsWith('`')) {
        return <code key={index}>{part.slice(1, -1)}</code>;
      }
      return part.split(/(\s+)/).map((subPart, subIndex) => (
        <span key={`${index}-${subIndex}`}>{subPart}</span>
      ));
    });
  };

// Get the current date and time
//const now = new Date();
//const currentDate = now.toLocaleDateString(); // e.g., "MM/DD/YYYY"
//const currentTime = now.toLocaleTimeString(); // e.g., "HH:MM:SS AM/PM"

  const handleDownloadPDF = () => {
    const element = document.createElement('div');
    // Create wrapper for content and border
    element.style.position = 'relative';
    element.style.width = '100%';
    element.style.maxWidth = '800px';
    element.style.margin = 'auto';
    element.style.backgroundColor = '#ffffff';
    element.style.padding = '20px';
    element.style.boxSizing = 'border-box';

    // Border wrapper
    const borderWrapper = document.createElement('div');
    borderWrapper.style.border = '6px double #333';
    borderWrapper.style.borderRadius = '8px';
    borderWrapper.style.padding = '20px'; // Ensure padding to include space around content
    borderWrapper.style.boxSizing = 'border-box';

    // Create header image element inside the border
    const header = document.createElement('img');
    header.src = StaryNight; // Use the imported header image
    header.style.width = '100%';
    header.style.height = 'auto';
    header.style.marginBottom = '0px'; // Add spacing after the header image

    // Clone the content and apply necessary styles
    const contentElement = document.getElementById('details-content').cloneNode(true);
    Array.from(contentElement.getElementsByTagName('p')).forEach((paragraph) => {
      paragraph.style.marginBottom = '1rem';
      paragraph.style.pageBreakInside = 'avoid';
    });
    contentElement.style.pageBreakAfter = 'always';

    // Append the header image and content to the border wrapper
    borderWrapper.appendChild(header);
    borderWrapper.appendChild(contentElement);
    element.appendChild(borderWrapper);

    // Generate PDF
    html2pdf()
      .set({
        filename: `${imageName}.pdf`,
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        margin: [20, 20, 40, 20], // top, right, bottom, left margins
        html2canvas: { scale: 2, logging: true, scrollY: 0 },
      })
      .from(element)
      .save();
  };

  const paragraphs = content
    .split(/\r?\n|\r|\n{2,}/)
    .filter((para) => para.trim() !== '');

  return (
    <div className="details-page-container">
      <h1 className="categories-title">Details | {imageName}</h1>
      
      <div className="details-content" id="details-content">
    
        {paragraphs.length > 0 ? (
          paragraphs.map((paragraph, index) => (
            
            <p key={index}>{parseTextWithFormatting(paragraph)}</p>
            
          ))
          
        ) : (
          <p className="coming-soon">Details Are Loading...</p>
        )}
      
      </div>

      <button className="back-button" onClick={() => navigate(-1)}>
        <i className="fas fa-arrow-left"></i> Back
      </button>

      <button className="download-button" onClick={handleDownloadPDF}>
        <i className="fas fa-download"></i> Download Details
      </button>
    </div>
  );
};

export default DetailsPage;
