import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable'; // Import swipeable
import './Catalogue.css';

const importAllImages = (r) =>
  r.keys().map((key) => ({
    src: r(key),
    name: key.split('/').pop().split('.')[0],
  }));

const images = importAllImages(require.context('../catlogueimages', false, /\.jpg$/));

const Catalogue = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleImageClick = (imageName) => {
    const txtFilePath = `/CategoriesDocs/${imageName}.txt`;

    setLoading(true);
    fetch(txtFilePath)
      .then((response) => {
        if (!response.ok) throw new Error('Text file not found');
        return response.text();
      })
      .then((textContent) => {
        setLoading(false);
        navigate(`/details/${imageName}`, { state: { content: textContent } });
      })
      .catch((error) => {
        console.error('Error loading .txt file:', error);
        setLoading(false);
        navigate(`/details/${imageName}`, {
          state: { content: 'Details not available.' },
        });
      });
  };

  const getThumbnails = () => {
    const totalImages = images.length;
    return [
      images[(currentIndex - 1 + totalImages) % totalImages],
      images[currentIndex],
      images[(currentIndex + 1) % totalImages],
    ];
  };

  const getDots = () => images.map((_, index) => index);

  // Swipe handlers for mobile
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length), // Go to next slide
    onSwipedRight: () =>
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length), // Go to previous slide
    preventDefaultTouchmoveEvent: true, // Prevent default scrolling
    trackMouse: true, // Enable swipe handling with mouse as well
  });

  return (
    <div className="catalogue-container">
      <h1 className="about-us-heading">Trending</h1>
      {loading && <div className="loading">LOADING...</div>}

      {images.length === 0 ? (
        <div className="coming-soon">Coming Soon</div>
      ) : (
        <>
          {/* Slideshow Section with Swipeable */}
          <div {...swipeHandlers} className="slideshow-container">
            <div
              className="slideshow-item"
              onClick={() => handleImageClick(images[currentIndex].name)}
            >
              <img
                src={images[currentIndex].src}
                alt={`Slide ${currentIndex + 1}`}
                className="slideshow-image"
              />
              <h3 className="catalogue-title">{images[currentIndex].name}</h3>
            </div>
          </div>

          {/* Thumbnail Section */}
          <div className="thumbnail-container">
            {getThumbnails().map((image, index) => (
              <div
                key={index}
                className={`thumbnail-item ${index === 1 ? 'active' : ''}`}
                onClick={() =>
                  setCurrentIndex((currentIndex + index - 1 + images.length) % images.length)
                }
              >
                <img src={image.src} alt={image.name} className="thumbnail-image" />
              </div>
            ))}
          </div>

          {/* Dot Indicators */}
          <div className="dot-container">
            {getDots().map((_, index) => (
              <div
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Catalogue;
